<template>
  <div class="repeat">
    <div class="repeat-header">
      <van-nav-bar title="转发" left-text="取消" @click-left="cancel">
        <template #right>
          <van-button type="info" round size="small" @click="handlerReaptPost">发布</van-button>
        </template>
      </van-nav-bar>

    </div>
    <div class="repeat-content">
      <div class="repeat-post-box">
        <textarea class="textarea" style="resize:none" autofocus placeholder="向大家说说你的看法" rows="13"
          v-model="content"></textarea>
      </div>
      <div class="repeat-parent-box">
        <div class="repeat-parent-content" v-html="post.article_info.content"></div>
      </div>

    </div>
    <van-action-sheet v-model="show" close-on-click-action :actions="actions" @select="onSelect" />
    <MessagePoint mesageTxt="已保存草稿箱" :show="isSaveDraft"></MessagePoint>
    <MessagePoint mesageTxt="转发成功" :show="isSucRepeat"></MessagePoint>
  </div>
</template>

<script>
import MessagePoint from '@/components/Mobile/MessagePoint'
export default {
  name: 'Repeat',
  components: {
    MessagePoint,
  },
  data() {
    return {
      post: {
        article_info: {}
      },
      // 输入的内容
      content: '',
      //是否展示弹出层
      show: false,
      // 是否保存至草稿箱
      isSaveDraft: false,
      // 是否成功转发
      isSucRepeat: false,
      actions: [{ name: '保存草稿' }, { name: '不保存', color: 'red' }, { name: '取消' }],
    };
  },
  activated() {
    this.getPostInfo();
  },
  mounted() {

  },

  methods: {
    // 获取帖子信息
    async getPostInfo() {
      const post_article_id = this.$route.query.post_article_id;
      let { data: res } = await this.api
        .postFormAPISM(
          {
            post_article_id,
          },
          "/article/getpostarticleinfo"
        )
      this.post = res.data;
    },
    // 转发帖子
    async handlerReaptPost() {
      const content = this.content;
      if (content === '') return
      try {
        let res = await this.api.postFormAPISM(
          {
            parent_post_article_id: this.post.article_info.id,
            content,
            is_draft: false,
            post_article_id: null,
          },
          '/article/repeat'
        )
        this.isSucRepeat = true;
        setTimeout(() => {
          //  // 关闭提示
          this.isSucRepeat = false;
        }, 1000)
        this.$router.replace('/research');
        this.content = '';
      } catch (error) {
        console.log(error);
      }
    },
    //取消
    cancel() {
      // 若写了发言，想要取消，则弹出下拉框
      if (this.content.length) {

        this.show = true;
      }
      else {
        this.$router.back(-1);
      }


    },
    async onSelect(item) {
      if (item.name === '保存草稿') {
        try {
          // 调用保存为草稿接口
          const post_article_id = this.post.article_info.id
          let { data: res } = await this.api
            .postFormAPISM(
              {
                title: '',
                content: this.content,
                post_article_id,
                is_draft: true
              },
              "/article/save"
            )
          this.isSaveDraft = true;
          setTimeout(() => {    
            this.isSaveDraft = false;
          }, 1000)
            // 关闭提示
            this.$router.push('/research');
            
            // 清空内容数据
            this.content = '';

        } catch (error) {
          console.log(error);
        }

      }
      else if (item.name === '不保存') {
        // 清空内容数据
        this.content = '';
        this.$router.replace('/research');
      }
      this.show = false;
    },

  },
};
</script>

<style lang="less" scoped>
@import './index.less';
</style>